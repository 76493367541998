import React from "react";
import { useEffect, useState } from "react";
import {
    Box,
    Button,
    Divider,
    Container,
    Heading,
    Stack,
    InputGroup,
    InputLeftAddon,
    Input,
    Select,
    Code,
    Text,
    Checkbox,
    Tooltip,
    Spinner,
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
    Accordion,
    AccordionItem,
    AccordionButton,
    AccordionPanel,
    AccordionIcon,
    Tabs,
    TabList,
    TabPanels,
    Tab,
    TabPanel,
    Card,
    useToast
} from "@chakra-ui/react";
import { DataGrid } from '@mui/x-data-grid';
import SearchIcon from '@mui/icons-material/Search';

import Header from "./components/Header";
import "./styles.css";
import JSONInput from 'react-json-editor-ajrm';
import axios from "axios";
import { useTable } from 'react-table';
import Plot from 'react-plotly.js'
import {
    Step,
    StepDescription,
    StepIcon,
    StepIndicator,
    StepNumber,
    StepSeparator,
    StepStatus,
    StepTitle,
    Stepper,
    useSteps,
} from '@chakra-ui/stepper';


const ORCHESTRATOR_URL = process.env.REACT_APP_ORCHESTRATOR_URL || "https://dialog-orchestrator-dev.ch.themama.ai";   // "http://localhost:8080"

const steps = [
    { title: 'Login', description: 'Log into Watson' },
    { title: 'Select', description: 'Select Orchestrator' },
    { title: 'Modify', description: 'Modify Orchestrator' },
    { title: 'Analysis', description: 'Skill(s) analysis' },
    { title: 'Save', description: 'Upload skill and update Orchestrator' }
]

const subSteps = [
    { title: 'Skill Analysis', content: SkillAnalysis},
    { title: 'Intents Overlap', content: IntentsOverlap},
    { title: 'Intents Plot', content: IntentsPlot},
    { title: 'Term Frequency Heatmap', content: TermFrequencyHeatmap},
    { title: 'Intents Comparison', content: IntentsComparison},
    { title: 'Entities Comparison', content: EntitiesComparison},
    { title: 'Finish Analysis', content: SkillAnalysisFinalization}
]

const languages = ['en', 'es', 'pt-br', 'fr', 'it', 'ja', 'de', 'ko', 'ar', 'nl', 'zh-tw', 'zh-cn', 'cs']


function Login({
    watsonAssistantConnectionDetails,
    username,
    password,
    loggedIn,
    onUsernameChange,
    onPasswordChange,
    onApiKeyChange,
    onRegionChange,
    isLoading,
    error,
    onContinue }
) {
    return (
        <>
            <Heading as='h3' size='lg'>Step 1. Log in</Heading>
            <Stack spacing={4}>
                <InputGroup size='sm'>
                    <InputLeftAddon children='Username' />
                    <Input
                        type="text"
                        name='username'
                        value={username}
                        placeholder='username'
                        onChange={onUsernameChange}
                    />
                </InputGroup>
                <InputGroup size='sm'>
                    <InputLeftAddon children='Password' />
                    <Input
                        type="text"
                        name='password'
                        value={password}
                        placeholder='password'
                        onChange={onPasswordChange}
                    />
                </InputGroup>
                <InputGroup size='sm'>
                    <InputLeftAddon children='Apikey' />
                    <Input
                        type="text"
                        name='apikey'
                        value={watsonAssistantConnectionDetails.apiKey}
                        placeholder='apikey'
                        onChange={onApiKeyChange}
                    />
                </InputGroup>
                <InputGroup size='sm'>
                    <InputLeftAddon children='Region' />
                    <Input
                        type="text"
                        name="region"
                        value={watsonAssistantConnectionDetails.region}
                        placeholder='region'
                        onChange={onRegionChange}
                    />
                </InputGroup>
                <div>
                    <Button onClick={onContinue} disabled={isLoading || loggedIn}>Get Access</Button>
                    {isLoading ? (
                        <div>
                            <p>Getting access...</p>
                            <div className='spinner'>
                                <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                                />
                            </div>
                        </div>
                    ) : (
                        <div>
                            {error ? (
                                <p>Error: {error}</p>
                            ) : (
                            <p>{loggedIn ? "Access Granted! :)" : "Waiting for credentials..."}</p>
                            )}
                        </div>
                    )}
                </div>
            </Stack>
        </>
    )
}

function OrchestratorSelection({
    selectedOrch, 
    itemsArray, 
    stepOrchestratorSelectionResponse,
    onOrchestratorSelectionChange, 
    onContinue}
) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <>
            <Heading as='h3' size='lg'>Step 2.1 Orchestrator Selection</Heading>
            <Stack spacing={4}>
                <label>Select Orchestrator</label>
                <div>
                    <Select value={selectedOrch} onChange={onOrchestratorSelectionChange}>
                        <option value="">No option</option>
                        {itemsArray.map((item) => (
                            <option key={item.workspace_id} value={item.workspace_id}>
                                {item.name}
                            </option>
                        ))}
                    </Select>
                </div>
                <label>
                    Selected Orchestrator id:
                </label>
                <div>
                    <Code>{selectedOrch}</Code>
                </div>
                <Button onClick={onContinue}>Retrieve Skills</Button>
                {stepOrchestratorSelectionResponse &&
                    <div>
                        <p>Skills retrieved</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Details
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        {JSON.stringify(stepOrchestratorSelectionResponse, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </Stack>
        </>
    )
}

function OrchestratorModification({ 
    availableMainSkill, 
    availableWorkerSkills,
    selectedWorkers, 
    availableAdditionalWorkerSkills, 
    selectedLang,
    stepOrchestratorModificationResponse,
    onWorkerSkillToggle, 
    onLanguageSelectionChange, 
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <>
            <div>
                <Heading as='h3' size='lg'>Step 2.2 Orchestrator Modification</Heading>
                <Heading as='h4' size='md'>Available skills</Heading>
                <Box bg='blue.50'>
                    <Heading size='sm'>Main skill</Heading>
                    <p>{availableMainSkill.name}</p>
                </Box>
                <Box bg='green.50'>
                    <Heading size='sm'>Worker skills</Heading>
                    {availableWorkerSkills.map((item_w) => (
                        <Box key={item_w.workspace_id}>
                            <Checkbox
                                value={item_w.workspace_id}
                                isChecked={selectedWorkers.includes(item_w)}
                                onChange={() => onWorkerSkillToggle(item_w)}
                            >
                                {item_w.name}
                            </Checkbox>

                        </Box>
                    ))}
                    {availableAdditionalWorkerSkills.map((item_w) => (
                        <Box key={item_w.workspace_id}>
                            <Checkbox
                                value={item_w.workspace_id}
                                isChecked={selectedWorkers.includes(item_w)}
                                onChange={() => onWorkerSkillToggle(item_w)}
                            >
                                {item_w.name}
                            </Checkbox>
                        </Box>
                    ))}
                </Box>
                <Box bg='yellow.50'>
                    <Heading size='sm'>Select Language</Heading>
                    <div>
                        <select
                            value={selectedLang}
                            onChange={onLanguageSelectionChange}
                        >
                            <option value="">No option</option>
                            {languages.map((language) => (
                                <option key={language} value={language}>
                                    {language}
                                </option>
                            ))}
                        </select>
                        <p>Selected Language: {selectedLang} </p>
                    </div>
                </Box>
                <div>
                    <Heading as='h4' size='md'>Final configuration</Heading>
                    <div>
                        <Heading size='sm'>Selected Main id</Heading>
                        <Code>
                            <pre>
                                {availableMainSkill.workspace_id}
                            </pre>
                        </Code>
                    </div>
                    <div>
                        <Heading size='sm'>{"Selected Worker id" + ((selectedWorkers.length > 1) ? "s" : "")}</Heading>
                        <Code>
                            <pre>
                                {JSON.stringify(selectedWorkers, null, 2)} 
                            </pre>
                        </Code>
                    </div>
                </div>
            </div>
            <div>
                <Button onClick={onContinue}>Generate Final Configuration</Button>
                {stepOrchestratorModificationResponse &&
                    <div>
                        <p>Final configuration generated</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Details
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        {JSON.stringify(stepOrchestratorModificationResponse, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        </>
    )
}

function SetSkillAnalysis({
    availableInputSkills,
    selectedWorkspaces,
    onWorkspacesToggle,
    SkillAnalysis,
    IntentsOverlap,
    IntentsPlot,
    TermFrequencyHeatmap,
    IntentsComparison,
    EntitiesComparison }
) {
    const [showDetails, setShowDetails] = useState(false);
    const [selectedTab, setSelectedTab] = useState(0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div>
            <Heading as='h3' size='lg'>Step 3. Skill Analysis</Heading>
            <div style={{ height: '20px' }}></div>
            <div>
                <Box bg='green.50'>
                    <Heading size='sm'>Input Skills for Analysis</Heading>
                    <div style={{ height: '5px' }}></div>
                        {availableInputSkills.map((item) => (
                            <Box key={item.workspace_id}>
                                <Checkbox
                                    value={item.workspace_id}
                                    isChecked={selectedWorkspaces.includes(item)}
                                    onChange={() => onWorkspacesToggle(item)}
                                >
                                {item.name}
                                </Checkbox>
                            </Box>
                        ))}
                </Box>
            </div>
            <div style={{ height: '20px' }}></div>
            <div id="accordion">
                <div className="card">
                    <div className="card-header" id="headingTwo">
                        <h5 className="mb-0">
                            <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                Details
                            </Button>
                        </h5>
                    </div>
                    <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                        <pre className="card-body">
                            <Heading size='sm'>{"Selected Skill" + ((selectedWorkspaces.length > 1) ? "s" : "")}</Heading>
                            <Code>
                                <pre>
                                    {JSON.stringify(selectedWorkspaces, null, 2)}
                                </pre>
                            </Code>
                        </pre>
                    </div>
                </div>
            </div>
            <div style={{ height: '40px' }}></div>
            <Card>
                <Tabs isFitted variant="solid-rounded">
                    <TabList>
                        <Tab isSelected={selectedTab === 0} onClick={() => handleTabChange(0)}>
                            Skill Analysis
                        </Tab>
                        <Tab isSelected={selectedTab === 1} onClick={() => handleTabChange(1)}>
                            Intents Overlap
                        </Tab>
                        <Tab isSelected={selectedTab === 2} onClick={() => handleTabChange(1)}>
                            Intents Plot
                        </Tab>
                        <Tab isSelected={selectedTab === 3} onClick={() => handleTabChange(1)}>
                             Term Frequency Heatmap
                        </Tab>
                        <Tab isSelected={selectedTab === 4} onClick={() => handleTabChange(1)}>
                             Intents Comparison
                        </Tab>
                        <Tab isSelected={selectedTab === 5} onClick={() => handleTabChange(1)}>
                             Entities Comparison
                        </Tab>
                    </TabList>
                    <Divider />
                    <TabPanels>
                        <TabPanel>
                            {SkillAnalysis}
                        </TabPanel>
                        <TabPanel>
                            {IntentsOverlap}
                        </TabPanel>
                        <TabPanel>
                            {IntentsPlot}
                        </TabPanel>
                        <TabPanel>
                            {TermFrequencyHeatmap}
                        </TabPanel>
                        <TabPanel>
                            {IntentsComparison}
                        </TabPanel>
                        <TabPanel>
                            {EntitiesComparison}
                        </TabPanel>
                    </TabPanels>
                </Tabs>
            </Card>
            <div style={{ height: '20px' }}></div>
        </div>
    )
}

function SkillAnalysis({
    responseStep31,
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);
    const tableStyle = {
        maxHeight: "300px",
        overflowY: "auto"}

    return (
        <>
            <div>
                <Heading size='sm'>3.1. Skill Analysis</Heading>
                <div style={{ height: '5px' }}></div>
                <Button onClick={onContinue}>Skill Analysis</Button>
                {responseStep31 &&
                    <div>
                    <div style={{ height: '20px' }}></div>
                        <Box bg='blue.50'>
                        <Heading size='sm'>Skill Analysis Table</Heading>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <div>
                                {Object.keys(responseStep31).map((skillName) => (
                                    <AccordionItem key={skillName}>
                                        {({ isExpanded }) => (
                                            <>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            {skillName}
                                                        </Box>
                                                        <AccordionIcon />
                                                        </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    {Object.keys(responseStep31[skillName]).map((partName) => (
                                                        <div key={partName}>
                                                            <Accordion defaultIndex={[0]} allowMultiple>
                                                                <Box bg='green.50'>
                                                                <AccordionItem>
                                                                    {({ isExpanded }) => (
                                                                        <>
                                                                            <h3>
                                                                                <AccordionButton>
                                                                                    <Box flex='1' textAlign='left'>
                                                                                        {partName}
                                                                                    </Box>
                                                                                    <AccordionIcon />
                                                                                </AccordionButton>
                                                                            </h3>
                                                                            <AccordionPanel pb={4}>
                                                                                {Object.keys(responseStep31[skillName][partName]).map((sectionName) => (
                                                                                    <div key={sectionName}>
                                                                                        <h4>{sectionName}</h4>
                                                                                        <Box bg='yellow.50' style={tableStyle}>
                                                                                        {Array.isArray(responseStep31[skillName][partName][sectionName]) ? (
                                                                                            <Table variant="simple">
                                                                                                <Thead>
                                                                                                    <Tr>
                                                                                                        {Object.keys(responseStep31[skillName][partName][sectionName][0]).map((columnName) => (
                                                                                                            <Th key={columnName}>{columnName}</Th>
                                                                                                        ))}
                                                                                                    </Tr>
                                                                                                </Thead>
                                                                                                <Tbody>
                                                                                                    {responseStep31[skillName][partName][sectionName].map((item, rowIndex) => (
                                                                                                        <Tr key={rowIndex}>
                                                                                                            {Object.keys(item).map((key) => (
                                                                                                            <Td key={key}>
                                                                                                                {key === "similarity score"
                                                                                                                    ? parseFloat(item[key]).toFixed(2)
                                                                                                                    : Array.isArray(item[key])
                                                                                                                    ? item[key].join(', ')
                                                                                                                    : item[key]}
                                                                                                            </Td>
                                                                                                            ))}
                                                                                                        </Tr>
                                                                                                    ))}
                                                                                                </Tbody>
                                                                                            </Table>
                                                                                        ) : (
                                                                                            Array.isArray(responseStep31[skillName][partName][sectionName]) ? (
                                                                                                <div>
                                                                                                    {responseStep31[skillName][partName][sectionName].map((sentence, index) => (
                                                                                                        <p key={index}>{sentence}</p>
                                                                                                    ))}
                                                                                                </div>
                                                                                            ) : (
                                                                                                <ul>
                                                                                                    {Object.keys(responseStep31[skillName][partName][sectionName]).map((key) => (
                                                                                                        <li key={key}>{key}</li>
                                                                                                    ))}
                                                                                                </ul>
                                                                                            )
                                                                                        )}
                                                                                        </Box>
                                                                                        <div style={{ height: '20px' }}></div>
                                                                                    </div>
                                                                                ))}
                                                                            </AccordionPanel>
                                                                        </>
                                                                    )}
                                                                </AccordionItem>
                                                                </Box>
                                                            </Accordion>
                                                            <div style={{ height: '20px' }}></div>
                                                        </div>
                                                    ))}
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                ))}
                            </div>
                        </Accordion>
                        </Box>
                        <div style={{ height: '20px' }}></div>
                        <p>Skill analysis completed!</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Details
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        <Heading size='sm'>{"Skill Analysis"}</Heading>
                                        {JSON.stringify(responseStep31, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div style={{ height: '20px' }}></div>
            </div>
        </>
    )
}

function IntentsOverlap({
    responseStep32,
    intent1,
    intent2,
    onIntent1Change,
    onIntent2Change,
    intentsArray,
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);
    const tableStyle = {
        maxHeight: "300px",
        overflowY: "auto"}

    return (
        <>
            <Heading size='sm'>3.2. Intents Overlap</Heading>
            <div style={{ height: '5px' }}></div>
            <div>
            <Box bg='yellow.50'>
                    <label>Intent 1</label>
                    <div>
                        <Select value={intent1} onChange={onIntent1Change}>
                            <option value="">No option</option>
                            {intentsArray.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Select>
                    </div>
                    <div style={{ height: '5px' }}></div>
                    <label>Intent 2</label>
                    <div>
                        <Select value={intent2} onChange={onIntent2Change}>
                            <option value="">No option</option>
                            {intentsArray.map((item) => (
                                <option key={item} value={item}>
                                    {item}
                                </option>
                            ))}
                        </Select>
                    </div>
                </Box>
            </div>
            <div style={{ height: '25px' }}></div>
            <div>
                <Button onClick={onContinue}>Intents Overlap</Button>
                {responseStep32 &&
                    <div>
                    <div style={{ height: '20px' }}></div>
                        <Box bg='blue.50'>
                        <Heading size='sm'>Intents Overlap Table</Heading>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <Box bg='blue.50'>
                            <div>
                                {Object.keys(responseStep32).map((skillName) => (
                                    <AccordionItem key={skillName}>
                                        {({ isExpanded }) => (
                                            <>
                                                <h2>
                                                    <AccordionButton>
                                                        <Box flex='1' textAlign='left'>
                                                            {skillName}
                                                        </Box>
                                                        <AccordionIcon />
                                                        </AccordionButton>
                                                </h2>
                                                <AccordionPanel pb={4}>
                                                    <Box bg='yellow.50' style={tableStyle}>
                                                    <Table variant="simple">
                                                        <Thead>
                                                            <Tr>
                                                                <Th>Intent Pairs</Th>
                                                                <Th>Terms</Th>
                                                            </Tr>
                                                        </Thead>
                                                        <Tbody>
                                                            {responseStep32[skillName]['Intents overlap'].map((intent, index) => (
                                                                <Tr key={index}>
                                                                    <Td>{intent['Intent Pairs']}</Td>
                                                                    <Td>{intent.Terms}</Td>
                                                                </Tr>
                                                            ))}
                                                        </Tbody>
                                                    </Table>
                                                    </Box>
                                                </AccordionPanel>
                                            </>
                                        )}
                                    </AccordionItem>
                                ))}
                            </div>
                            </Box>
                        </Accordion>
                        </Box>
                        <div style={{ height: '20px' }}></div>
                        <p>Intents overlap completed!</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Details
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        <Heading size='sm'>{"Intents Overlap"}</Heading>
                                        {JSON.stringify(responseStep32, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div style={{ height: '40px' }}></div>
            </div>
        </>
    )
}

function IntentsPlot({
    responseStep33,
    selectedWorkspaces,
    workspacePlot,
    onWorkspaceChangePlot,
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Heading size='sm'>3.3. Intents Plot</Heading>
            <div style={{ height: '5px' }}></div>
            <div>
                <Box bg='green.50'>
                    <label>Select Workspace</label>
                    <div>
                        <Select value={workspacePlot} onChange={onWorkspaceChangePlot}>
                            <option value="">No option</option>
                            {selectedWorkspaces.map((item) => (
                                <option key={item.workspace_id} value={item.workspace_id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                </Box>
            </div>
            <div>
            <div style={{ height: '20px' }}></div>
                <Button onClick={onContinue}>Intents Plot</Button>
                {responseStep33 &&
                    <div>
                    <div style={{ height: '20px' }}></div>
                        <p>Intents plot completed!</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Show Plot
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        <Heading size='sm'>{"Intents Plot"}</Heading>
                                        {responseStep33 && <img src={responseStep33} onClick={toggleModal} style={{cursor: 'pointer'}}/>}
                                        {isOpen && (
                                            <div className="modal" onClick={toggleModal}>
                                                <div className="modal-content">
                                                    <span className="close" onClick={toggleModal}>
                                                        &times;
                                                    </span>
                                                    <img src={responseStep33} />
                                                </div>
                                            </div>
                                          )}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div style={{ height: '20px' }}></div>
            </div>
        </>
    )
}

function TermFrequencyHeatmap({
    responseStep34,
    selectedWorkspaces,
    workspaceHeatmap,
    onWorkspaceChangeHeatmap,
    onSelectionIntentsToggle,
    chooseIntents,
    selectedIntents,
    onContinueGetIntents,
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const toggleModal = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Heading size='sm'>3.4. Term Frequency Heatmap</Heading>
            <div style={{ height: '5px' }}></div>
            <div>
                <Box bg='green.50'>
                    <label>Select Workspace</label>
                    <div>
                        <Select value={workspaceHeatmap ? workspaceHeatmap.workspace_id: ''} onChange={onWorkspaceChangeHeatmap}>
                            <option value="">No option</option>
                            {selectedWorkspaces.map((item) => (
                                <option key={item.workspace_id} value={item.workspace_id}>
                                    {item.name}
                                </option>
                            ))}
                        </Select>
                    </div>
                </Box>
                <div style={{ height: '20px' }}></div>
                <Button onClick={onContinueGetIntents}>Retrieve Intents</Button>
                <div style={{ height: '20px' }}></div>
                <div>
                <Box bg='yellow.50'>
                    <label>Select Intents</label>
                        {chooseIntents.map((item) => (
                            <Box key={item}>
                                <Checkbox
                                    value={item}
                                    isChecked={selectedIntents.includes(item)}
                                    onChange={() => onSelectionIntentsToggle(item)}
                                >
                                {item}
                                </Checkbox>
                            </Box>
                        ))}
                </Box>
            </div>
            <div style={{ height: '20px' }}></div>
            </div>
            <div>
                <Button onClick={onContinue}>Term Frequency Heatmap</Button>
                <div style={{ height: '20px' }}></div>
                {responseStep34 &&
                    <div>
                        <p>Term frequency heatmap completed!</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Show Heatmap
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        <Heading size='sm'>{"Term Frequency Heatmap"}</Heading>
                                        {responseStep34 && <img src={responseStep34} onClick={toggleModal} style={{cursor: 'pointer'}}/>}
                                        {isOpen && (
                                            <div className="modal" onClick={toggleModal}>
                                                <div className="modal-content">
                                                    <span className="close" onClick={toggleModal}>
                                                        &times;
                                                    </span>
                                                    <img src={responseStep34} />
                                                </div>
                                            </div>
                                          )}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div style={{ height: '20px' }}></div>
            </div>
        </>
    )
}

function IntentsComparison({
    responseStep35,
    isLoading,
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);
    const tableStyle = {
        maxHeight: "500px",
        overflowY: "auto"}

    return (
        <>
            <Heading size='sm'>3.5. Intents Comparison</Heading>
            <div style={{ height: '5px' }}></div>
            <div>
                <Button onClick={onContinue}>Intents Comparison</Button>
                {isLoading ? (
                        <div>
                            <p>Generating intents comparison report...</p>
                            <div className='spinner'>
                                <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                                />
                            </div>
                        </div>
                    ) : (
                        <p>{responseStep35 ? "" : ""}</p>
                    )
                }
                {responseStep35 &&
                    <div>
                    <div style={{ height: '20px' }}></div>
                    <div>
                        <Box bg='blue.50'>
                        <Heading size='sm'>Intents Comparison Table</Heading>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                        Conflicting Examples
                                    </Box>
                                    <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                    <Box bg='green.50' style={tableStyle}>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>Workspaces</Th>
                                                <Th>Intents</Th>
                                                <Th>Examples</Th>
                                                <Th>Similarity Score</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {responseStep35["Intents comparison"]["Conflicting examples"].map(
                                                (example, index) => (
                                                    <Tr key={index}>
                                                        <Td>{example["Workspace 1"]}, {example["Workspace 2"]}</Td>
                                                        <Td>{example["Intent 1"]}, {example["Intent 2"]}</Td>
                                                        <Td>{example["Example 1"]}, {example["Example 2"]}</Td>
                                                        <Td>{parseFloat(example["Similarity score"]).toFixed(2)}</Td>
                                                    </Tr>
                                                )
                                            )}
                                        </Tbody>
                                    </Table>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                        Conflicts Report
                                    </Box>
                                    <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                    <Box bg='green.50' style={tableStyle}>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>Workspaces</Th>
                                                <Th>Intents</Th>
                                                <Th>Conflict Report</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {responseStep35["Intents comparison"]["Conflicts report"].map(
                                                (report, index) => (
                                                    <Tr key={index}>
                                                        <Td>{report["Workspace 1"]}, {report["Workspace 2"]}</Td>
                                                        <Td>{report["Intent 1"]}, {report["Intent 2"]}</Td>
                                                        <Td>{report["Conflict report"]}</Td>
                                                    </Tr>
                                                )
                                            )}
                                        </Tbody>
                                    </Table>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                        </Box>
                    </div>
                    <div style={{ height: '20px' }}></div>
                        <p>Intents comparison completed!</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Details
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        <Heading size='sm'>{"Intents Comparison"}</Heading>
                                        {JSON.stringify(responseStep35, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div style={{ height: '20px' }}></div>
            </div>
        </>
    )
}

function EntitiesComparison({
    responseStep36,
    isLoading,
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);
    const tableStyle = {
        maxHeight: "500px",
        overflowY: "auto"}

    return (
        <>
            <Heading size='sm'>3.6. Entities Comparison</Heading>
            <div style={{ height: '5px' }}></div>
            <div>
                <Button onClick={onContinue}>Entities Comparison</Button>
                {isLoading ? (
                        <div>
                            <p>Generating entities comparison report...</p>
                            <div className='spinner'>
                                <Spinner
                                thickness='4px'
                                speed='0.65s'
                                emptyColor='gray.200'
                                color='blue.500'
                                size='xl'
                                />
                            </div>
                        </div>
                    ) : (
                        <p>{responseStep36 ? "" : ""}</p>
                    )
                }
                {responseStep36 &&
                    <div>
                    <div style={{ height: '20px' }}></div>
                    <div>
                        <Box bg='blue.50'>
                        <Heading size='sm'>Entities Comparison Table</Heading>
                        <Accordion defaultIndex={[0]} allowMultiple>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                        Conflicting Values
                                    </Box>
                                    <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                    <Box bg='green.50' style={tableStyle}>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>Workspaces</Th>
                                                <Th>Entities</Th>
                                                <Th>Values</Th>
                                                <Th>Synonyms</Th>
                                                <Th>Similarity Score</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {responseStep36["Entities comparison"]["Conflicting values"].map(
                                                (example, index) => (
                                                    <Tr key={index}>
                                                        <Td>{example["Workspace 1"]}, {example["Workspace 2"]}</Td>
                                                        <Td>{example["Entity 1"]}, {example["Entity 2"]}</Td>
                                                        <Td>{example["Value 1"]}, {example["Value 2"]}</Td>
                                                        <Td>{example["Synonym 1"]}, {example["Synonym 2"]}</Td>
                                                        <Td>{parseFloat(example["Similarity score"]).toFixed(2)}</Td>
                                                    </Tr>
                                                )
                                            )}
                                        </Tbody>
                                    </Table>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                            <AccordionItem>
                                <h2>
                                    <AccordionButton>
                                    <Box flex='1' textAlign='left'>
                                        Conflicts Report
                                    </Box>
                                    <AccordionIcon />
                                    </AccordionButton>
                                </h2>
                                <AccordionPanel>
                                    <Box bg='green.50' style={tableStyle}>
                                    <Table variant="simple">
                                        <Thead>
                                            <Tr>
                                                <Th>Workspaces</Th>
                                                <Th>Entities</Th>
                                                <Th>Conflict Report</Th>
                                            </Tr>
                                        </Thead>
                                        <Tbody>
                                            {responseStep36["Entities comparison"]["Conflicts report"].map(
                                                (report, index) => (
                                                    <Tr key={index}>
                                                        <Td>{report["Workspace 1"]}, {report["Workspace 2"]}</Td>
                                                        <Td>{report["Entity 1"]}, {report["Entity 2"]}</Td>
                                                        <Td>{report["Conflict report"]}</Td>
                                                    </Tr>
                                                )
                                            )}
                                        </Tbody>
                                    </Table>
                                    </Box>
                                </AccordionPanel>
                            </AccordionItem>
                        </Accordion>
                        </Box>
                    </div>
                    <div style={{ height: '20px' }}></div>
                        <p>Entities comparison completed!</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Details
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        <Heading size='sm'>{"Entities Comparison"}</Heading>
                                        {JSON.stringify(responseStep36, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div style={{ height: '20px' }}></div>
            </div>
        </>
    )
}

function SkillAnalysisFinalization({ 
    stepSkillAnalysisFinalizationResponse,
    responseStep37,
    onConfirm,
    onContinue }
) {
    const [showDetails, setShowDetails] = useState(false);

    return (
        <div>
            <Heading size='sm'>Finish Skill Analysis</Heading>
            <div style={{ height: '5px' }}></div>
            <div>
                <Button onClick={onContinue}>Finish skill analysis</Button>
                <div style={{ height: '20px' }}></div>
                {stepSkillAnalysisFinalizationResponse &&
                    <div>
                        <p>{responseStep37[0]}</p>
                        <div id="accordion">
                            <div className="card">
                                <div className="card-header" id="headingTwo">
                                    <h5 className="mb-0">
                                        <Button onClick={() => setShowDetails(!showDetails)} className="btn btn-link">
                                            Details
                                        </Button>
                                    </h5>
                                </div>
                                <div id="collapseOne" className={"collapse" + (showDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                    <pre className="card-body">
                                        <Heading size='sm'>{"Skill Analysis Report"}</Heading>
                                        {JSON.stringify(stepSkillAnalysisFinalizationResponse, null, 2)}
                                    </pre>
                                </div>
                            </div>
                        </div>
                    </div>
                }
            <div style={{ height: '20px' }}></div>
            <Button onClick={onConfirm}>Confirm & Continue</Button>
            </div>
        <div style={{ height: '20px' }}></div>
        </div>
    )
}

function RootSkillCreation({ 
    stepSkillAnalysisFinalizationResponse, 
    responseStep41, 
    responseStep42, 
    responseStep43,
    onGenerateFinalConfig, 
    onSkillUpload,  
    onOrchestratorUpdate } 
) {
    const [assistantIds, setAssistantIds] = useState({});
    const [showRootSkillCreatorDetails, setShowRootSkillCreatorDetails] = useState(false);
    const [showUploadSkillDetails, setShowUploadSkillDetails] = useState(false);
    const [showUpdateOrchestratorDetails, setShowUpdateOrchestratorDetails] = useState(false);

    const missingAssistantIds = stepSkillAnalysisFinalizationResponse['state']['final_configuration']['worker_skills'].find((skill) => !skill.hasOwnProperty('assistant_id') || !skill['assistant_id']);
    const availableSkills = [...stepSkillAnalysisFinalizationResponse.state.available_skills_per_orchestrator.worker_skills, ...stepSkillAnalysisFinalizationResponse.state.available_additional_workspaces]
    const availableSkillsByWorkspaceId = Object.fromEntries(availableSkills.map(skill => [skill.workspace_id, skill]));

    return (
        <>
            <div>
                <Heading as='h3' size='lg'>Step 4. Execution</Heading>
                {missingAssistantIds &&
                    <>
                        <p>Fill in missing Assistant IDs</p>
                        {
                            stepSkillAnalysisFinalizationResponse['state']['final_configuration']['worker_skills'].filter((skill) => !skill.hasOwnProperty('assistant_id') || !skill['assistant_id']).map((skill) => (
                                <InputGroup key={skill.workspace_id} size='sm'>
                                    <Tooltip hasArrow label={availableSkillsByWorkspaceId[skill.workspace_id].workspace_id} bg='gray.300' color='black'>
                                        <InputLeftAddon children={availableSkillsByWorkspaceId[skill.workspace_id].name} />
                                    </Tooltip>
                                    <Input
                                        type="text"
                                        name='assistant_id'
                                        value={assistantIds[skill.workspace_id]?.assistant_id || ''}
                                        placeholder='Assistant ID'
                                        onChange={(e) => setAssistantIds({
                                            ...assistantIds,
                                            [skill.workspace_id]: {
                                                ...assistantIds[skill.workspace_id],
                                                assistant_id: e.target.value
                                            }
                                        })}
                                    />
                                </InputGroup>
                            ))
                        }
                    </>
                }
                <pre>{JSON.stringify(assistantIds, null, 2)}</pre>
                {false && Object.values(availableSkillsByWorkspaceId).map((skill) => (
                    <Code>
                        <pre>{JSON.stringify(skill, null, 2)}</pre>
                    </Code>
                ))}
                <p>Run Root Skill Creator</p>
                <div>
                    <Button onClick={() => onGenerateFinalConfig(assistantIds)}>Run Root Skill Creator</Button>
                    {responseStep41 &&
                        <div>
                            <p>Root skill created</p>
                            <div id="accordion">
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <Button onClick={() => setShowRootSkillCreatorDetails(!showRootSkillCreatorDetails)} className="btn btn-link">
                                                Details
                                            </Button>
                                        </h5>
                                    </div>
                                    <div id="collapseOne" className={"collapse" + (showRootSkillCreatorDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                        <pre className="card-body">
                                            {responseStep41}
                                        </pre>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
                {responseStep41 &&
                    <>
                        <p>Update Orchestrator Skill</p>
                        <div>
                            <Button onClick={onSkillUpload}>Update Skill</Button>
                            {responseStep42 &&
                                <div>
                                    <p>Root (orchestrator) skill uploaded</p>
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="mb-0">
                                                    <Button onClick={() => setShowUploadSkillDetails(!showUploadSkillDetails)} className="btn btn-link">
                                                        Details
                                                    </Button>
                                                </h5>
                                            </div>
                                            <div id="collapseOne" className={"collapse" + (showUploadSkillDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                                <pre className="card-body">
                                                    {responseStep42}
                                                </pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }
                {responseStep42 &&
                    <>
                        <p>Update Orchestrator Config</p>
                        <div>
                            <Button onClick={onOrchestratorUpdate}>Update Config</Button>
                            {responseStep43 &&
                                <div>
                                    <p>Orchestrator config updated</p>
                                    <div id="accordion">
                                        <div className="card">
                                            <div className="card-header" id="headingTwo">
                                                <h5 className="mb-0">
                                                    <Button onClick={() => setShowUpdateOrchestratorDetails(!showUpdateOrchestratorDetails)} className="btn btn-link">
                                                        Details
                                                    </Button>
                                                </h5>
                                            </div>
                                            <div id="collapseOne" className={"collapse" + (showUpdateOrchestratorDetails ? " show" : "")} aria-labelledby="headingOne" data-parent="#accordion">
                                                <pre className="card-body">
                                                    {responseStep43}
                                                </pre>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        </>
    )
}

function JsonEditorStep() {
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')

    const [activeStep, setActiveStep] = useState(0);

    // LogIn step
    const [loggedIn, setLoggedIn] = useState(false);
    const [watsonAssistantConnectionDetails, setWatsonAssistantConnectionDetails] = useState({
        apiKey: '',
        region: 'eu-de',
        version: '2021-11-27'
    });
    const toast = useToast();

    // OrchestratorSelection step
    const [selectedOrch, setSelectedOrch] = useState("");
    const [itemsArray, setItemsArray] = useState([]);
    const [stepOrchestratorSelectionResponse, setStepOrchestratorSelectionResponse] = useState();

    // OrchestratorModification step
    const [availableMainSkill, setAvailableMainSkill] = useState("");
    const [availableWorkerSkills, setAvailableWorkerSkills] = useState([]);
    const [selectedWorkers, setSelectedWorkers] = useState([]);
    const [availableAdditionalWorkerSkills, setAvailableAdditionalWorkerSkills] = useState([]);
    const [selectedLang, setSelectedLang] = useState("cs");
    const [stepOrchestratorModificationResponse, setStepOrchestratorModificationResponse] = useState();

    // SkillAnalysis steps
    const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
    const [availableInputSkills, setAvailableInputSkills] = useState([]);
    const [stepSkillAnalysisFinalizationResponse, setStepSkillAnalysisFinalizationResponse] = useState();
    const [intentsArray, setIntentsArray] = useState([]);
    const [selectedIntentsArray, setSelectedIntentsArray] = useState([]);
    const [intent1, setIntent1] = useState("");
    const [intent2, setIntent2] = useState("");
    const [workspacePlot, setWorkspacePlot] = useState("");
    const [workspaceHeatmap, setWorkspaceHeatmap] = useState("");
    const [selectedIntents, setSelectedIntents] = useState([])
    const [chooseIntents, setChooseIntents] = useState([])

    const [responseStep31, setResponseStep31] = useState("");
    const [responseStep32, setResponseStep32] = useState("");
    const [responseStep33, setResponseStep33] = useState("");
    const [responseStep34, setResponseStep34] = useState("");
    const [responseStep35, setResponseStep35] = useState("");
    const [responseStep36, setResponseStep36] = useState("");
    const [responseStep37, setResponseStep37] = useState("");

    // RootSkillCreation step
    const [responseStep41, setResponseStep41] = useState("");
    const [responseStep42, setResponseStep42] = useState("");
    const [responseStep43, setResponseStep43] = useState("");

    // FIXME - are these states necessary???
    const [orchestratorSelected, setOrchestratorSelected] = useState(false);
    const [orchestratorModified, setOrchestratorModified] = useState(false);
    const [analysisCompleted, setAnalysisCompleted] = useState(false);
    const [responseStep211, setResponseStep211] = useState("");
    const [isLoading, setIsLoading] = useState(false)

    const headers_auth = {
        "Authorization": `Basic ${btoa(`${username}:${password}`)}`,
        "Content-Type": "application/json",
    }

    function trimIndex(index) {
        let maxIndex = index;
        if (!loggedIn) {
            maxIndex = 0;
        } else if (!orchestratorSelected) {
            maxIndex = 1;
        } else if (!orchestratorModified) {
            maxIndex = 2;
        } else if (!analysisCompleted) {
            maxIndex = 3;
        } else {
            maxIndex = 4;
        }
        return Math.min(index, maxIndex);
    }

    const handleWorkerSkillToggleChange = (itemId) => {
        if (selectedWorkers.includes(itemId)) {
            setSelectedWorkers(selectedWorkers.filter((item) => item !== itemId));
        } else {
            setSelectedWorkers([...selectedWorkers, itemId]);
        };
    };

    const handleSkillsAnalysisChange = (itemId) => {
        if (selectedWorkspaces.includes(itemId)) {
            setSelectedWorkspaces(selectedWorkspaces.filter((item) => item !== itemId));
        } else {
            setSelectedWorkspaces([...selectedWorkspaces, itemId]);
        };
    };

    const handleSelectionIntentsArrayToggle = (itemId) => {
        if (selectedIntentsArray.includes(itemId)) {
            setSelectedIntentsArray(selectedIntentsArray.filter((item) => item !== itemId));
        } else {
            setSelectedIntentsArray([...selectedIntentsArray, itemId]);
        };
    };

    const handleSelectionIntentsToggle = (itemId) => {
        if (selectedIntents.includes(itemId)) {
            setSelectedIntents(selectedIntents.filter((item) => item !== itemId));
        } else {
            setSelectedIntents([...selectedIntents, itemId]);
        };
    };

    const selectAllIntents = () => {
        setSelectedIntentsArray(intentsArray);
    };

    const deselectAllIntents = () => {
        setSelectedIntentsArray([]);
    };

    const handleOrchestratorSelectionChange = (e) => {
        setSelectedOrch(e.target.value);
    }

    const handleLanguageSelectionChange = (e) => {
        setSelectedLang(e.target.value);
    }

    const handleIntent1Change = (e) => {
        setIntent1(e.target.value);
    };

    const handleIntent2Change = (e) => {
        setIntent2(e.target.value);
    };

    const handleWorkspaceChangePlot = (e) => {
        setWorkspacePlot(e.target.value);
    };

    const handleWorkspaceChangeHeatmap = (e) => {
        const selectedValue = e.target.value;
        const selectedWorkspaceObject = selectedWorkspaces.find(
            (item) => item.workspace_id === selectedValue
        );
        setWorkspaceHeatmap(selectedWorkspaceObject);
    };

    const handleUsername = (e) => {
        setUsername(e.target.value);
    };

    const handlePassword = (e) => {
        setPassword(e.target.value);
    };
    const handleApiKeyInput = (event) => {
        setWatsonAssistantConnectionDetails({
            ...watsonAssistantConnectionDetails,
            apiKey: event.target.value
        });
    };

    const handleRegionInput = (event) => {
        setWatsonAssistantConnectionDetails({
            ...watsonAssistantConnectionDetails,
            region: event.target.value
        });
    };

    const handleVersionInput = (event) => {
        setWatsonAssistantConnectionDetails({
            ...watsonAssistantConnectionDetails,
            version: event.target.value
        });
    };

    const handleStepperClick = (index) => {
        let trimmedIndex = trimIndex(index);
        setActiveStep(trimmedIndex);
    }

    function handleErrors(response) {
        if (!response.ok) {
            throw Error(response.statusText);
        }
        return response;
    }

    const handleStepLogin = async (e) => {
        e.preventDefault();
        setIsLoading(true);

        const headers = {
            "apikey": watsonAssistantConnectionDetails.apiKey,
            "region": watsonAssistantConnectionDetails.region,
            "version": watsonAssistantConnectionDetails.version,
            "Authorization": `Basic ${btoa(`${username}:${password}`)}`
        };

        try {
            // endpoint step1
            let response = await fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step1`, {
                method: "GET",
                headers: headers
            });
            response = handleErrors(response);
            let data = await response.json();
            let stepLoginResponse = JSON.stringify(data, null, 2);

            // endpoint step2-1-1
            response = await fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step2-1-1`, {
                method: "POST",
                headers: headers_auth,
                body: stepLoginResponse,
            });
            response = handleErrors(response);
            data = await response.json();
            const availableOrch = data.state.available_orchestrators || {};
            const items = Object.values(availableOrch).map((item) => ({
                name: item.name,
                workspace_id: item.workspace_id,
            }));

            // update the state
            setResponseStep211(data);
            setLoggedIn(true);
            setActiveStep(1);
            setItemsArray(items);
            setIsLoading(false);

        } catch (error) {
            console.error("Error fetching data: ", error);
            toast({
                title: 'Login Failed',
                description: error.message,
                status: 'error',
                duration: 5000,
                isClosable: true,
              });

        } finally {
            setIsLoading(false);

        }
    };

    const handleStepOrchestratorSelection = async () => {
        if (!selectedOrch) {
            return;
        }
        const requestBody = {
            ...responseStep211,
            input: {
                selected_orchestrator_id: selectedOrch
            }
        }

        try {
            // endpoint step2-1-2
            let response = await fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step2-1-2`, {
                method: "POST",
                headers: headers_auth,
                body: JSON.stringify(requestBody, null, 2),
            });
            response = handleErrors(response);
            let data = await response.json();
            let step212Response = JSON.stringify(data, null, 2);

            // endpoint step2-2-1
            response = await fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step2-2-1`, {
                method: "POST",
                headers: headers_auth,
                body: step212Response,
            });
            response = handleErrors(response);
            data = await response.json();

            const availableMainSkill = data.state.available_skills_per_orchestrator.main_skill || {};
            const { workspace_id, name } = availableMainSkill;

            const availableWorkerSkills = data.state.available_skills_per_orchestrator.worker_skills || {};
            const items_w = Object.values(availableWorkerSkills).map((item_w) => ({
                name: item_w.name,
                workspace_id: item_w.workspace_id,
            }));

            const availableAdditionalWorkerSkills = data.state.available_additional_workspaces || {};
            const items_a = Object.values(availableAdditionalWorkerSkills).map((item_a) => ({
                name: item_a.name,
                workspace_id: item_a.workspace_id,
            }));

            // update the state
            setOrchestratorSelected(true);
            setActiveStep(2);
            setStepOrchestratorSelectionResponse(data);
            setAvailableMainSkill({ workspace_id, name });
            setAvailableWorkerSkills(items_w);
            setSelectedWorkers(items_w);
            setAvailableAdditionalWorkerSkills(items_a);

        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    const handleStepOrchestratorModification = () => {
        const selectedWorkerSkills = selectedWorkers.map((selectedItem) => (selectedItem))
        const inputSkills = selectedWorkers.map((selectedItem) => ({
            workspace_id: selectedItem.workspace_id,
            name: selectedItem.name
        }));
            setAvailableInputSkills([availableMainSkill, ...inputSkills])
            setSelectedWorkspaces([availableMainSkill, ...inputSkills])

        const requestBody = {
            ...stepOrchestratorSelectionResponse,
            input: {
                main_skill: {
                    workspace_id: availableMainSkill.workspace_id,
                    name: availableMainSkill.name
                },
                worker_skills: selectedWorkerSkills
            }
        }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step2-2-2`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(requestBody, null, 2),
        })
            .then(handleErrors)
            .then((response) => response.json())
            .then((data) => {
                setStepOrchestratorModificationResponse(data);
                setOrchestratorModified(true);
                setActiveStep(3);
            })
            .catch((error) => console.error("Error fetching data: ", error));
    };

    const handleSkillAnalysis = async () => {
        const selectedInputSkills = selectedWorkspaces.map((selectedItem) => ({ workspace_id: selectedItem.workspace_id }));

        const requestBody = {
            ...stepOrchestratorModificationResponse,
            input: {
                selected_workspaces: selectedInputSkills
            }
        }

        try {
            // endpoint step 3-1
            let response = await fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step3-1`, {
                method: "POST",
                headers: headers_auth,
                body: JSON.stringify(requestBody, null, 2),
            });
            response = handleErrors(response);
            let step31Response = await response.json();

            const intentValueSkill = {};
            for (const key in step31Response.state.workspace_report) {
                const skillData = step31Response.state.workspace_report[key]
                const availableIntents = skillData['Part 3. Data distribution']['Examples per intent'] || {};
                const intentValues = [];
                availableIntents.forEach((item) => {
                    if (item.Intent) {
                        intentValues.push(item.Intent);
                    }
                });
                intentValueSkill[key] = intentValues;
            }
            const allIntents = Object.values(intentValueSkill).flat();
            const uniqueIntentsSet = new Set(allIntents);

            // update state
            const reportStep31 = step31Response.state.workspace_report
                setResponseStep31(reportStep31);
            setIntentsArray(Array.from(uniqueIntentsSet))
            //setSelectedIntentsArray(Array.from(uniqueIntentsSet))
            setOrchestratorModified(true);
            setActiveStep(3);

        } catch (error) {
            console.error("Error fetching data: ", error);
        }
    };

    const handleIntentsOverlap = () => {
        const selectedInputSkills = selectedWorkspaces.map((selectedItem) => ({ workspace_id: selectedItem.workspace_id }));
        const requestBody = {
                ...stepOrchestratorModificationResponse,
                input: {
                    intents_list: [intent1, intent2],
                    selected_workspaces: selectedInputSkills
                }
            }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step3-2`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(requestBody, null, 2),
        })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => {
            const reportStep32 = data.state.intents_overlap
            setResponseStep32(reportStep32)
        })
        .catch((error) => console.error("Error fetching data: ", error));
    };

    const handleIntentsPlot = () => {
        const requestBody = {
                ...stepOrchestratorModificationResponse,
                input: {
                    selected_workspaces: [{
                    workspace_id: workspacePlot
                    }]
                }
            }

        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step3-3`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(requestBody, null, 2),
        })
        .then(handleErrors)
        .then((response) => response.blob())
        .then((blob) => {
            const objectUrl = URL.createObjectURL(blob)
            setResponseStep33(objectUrl)
        })
        .catch((error) => console.error("Error fetching data: ", error));
    };

    const handleIntentsHeatmap = () => {
        const intentValueSkill = {};
        const skillData = responseStep31[workspaceHeatmap.name];

        if (skillData) {
            const availableIntents = skillData['Part 3. Data distribution']['Examples per intent'] || {};
            const intentValues = [];
            availableIntents.forEach((item) => {
                if (item.Intent) {
                    intentValues.push(item.Intent);
                }
        });
            intentValueSkill[workspaceHeatmap.name] = intentValues;
            setChooseIntents(intentValues);
            setSelectedIntents(intentValues);
        } else {
            setResponseStep34("Run 3.1. to display intents")
        }
    };

    const handleTermFrequencyHeatmap = async () => {
        const requestBody = {
            ...stepOrchestratorModificationResponse,
            input: {
                intents_list: selectedIntents,
                selected_workspaces: [{
                    workspace_id: workspaceHeatmap.workspace_id
                    }]
                }
        }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step3-4`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(requestBody, null, 2),
        })
        .then(handleErrors)
        .then((response) => response.blob())
        .then((blob) => {
            const objectUrl = URL.createObjectURL(blob)
            //setResponseStep34(objectUrl && <img src={objectUrl} />)
            setResponseStep34(objectUrl)
        })
        .catch((error) => console.error("Error fetching data: ", error));
    };

    const handleIntentsComparison = () => {
        setIsLoading(true);
        const selectedInputSkills = selectedWorkspaces.map((selectedItem) => ({ workspace_id: selectedItem.workspace_id }));
        const requestBody = {
                ...stepOrchestratorModificationResponse,
                input: {
                    selected_workspaces: selectedInputSkills
                }
            }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step3-5`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(requestBody, null, 2),
        })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => {
            const reportStep35 = data.state.intents_comparison
            setResponseStep35(reportStep35)
            setIsLoading(false);
        })
        .catch((error) => console.error("Error fetching data: ", error));
    };

    const handleEntitiesComparison = () => {
        setIsLoading(true);
        const selectedInputSkills = selectedWorkspaces.map((selectedItem) => ({ workspace_id: selectedItem.workspace_id }));
        const requestBody = {
                ...stepOrchestratorModificationResponse,
                input: {
                    selected_workspaces: selectedInputSkills
                }
            }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step3-6`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(requestBody, null, 2),
        })
        .then(handleErrors)
        .then((response) => response.json())
        .then((data) => {
            const reportStep36 = data.state.entities_comparison
            setResponseStep36(reportStep36)
            setIsLoading(false);
        })
        .catch((error) => console.error("Error fetching data: ", error));
    };

    const handleStepSkillAnalysisFinalization = () => {
        if (!stepOrchestratorModificationResponse) {
            return;
        }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step3-7`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(stepOrchestratorModificationResponse, null, 2),
        })
            .then(handleErrors)
            .then((response) => response.json())
            .then((data) => {
                setResponseStep37(data.state.end_analysis_report);
                data.state.workspace_report = responseStep31
                data.state.intents_overlap = responseStep32
                data.state.intents_comparison = responseStep35
                data.state.entities_comparison = responseStep36
                setStepSkillAnalysisFinalizationResponse(data);
                //setAnalysisCompleted(true);
                //setActiveStep(4);
            })
            .catch((error) => console.error("Error fetching data: ", error));
    };

    const handleConfirmation = () => {
        setAnalysisCompleted(true);
        setActiveStep(4);
    }

    const handleStepRootSkillCreatorSubstepGenerateFinalConfig = (assistantIds) => {
        const requestBody = {
            ...stepSkillAnalysisFinalizationResponse,
            input: {
                final_configuration: {
                    ...stepSkillAnalysisFinalizationResponse.state.final_configuration,
                    __language__: selectedLang
                }
            }
        }
        for (const skill of requestBody.input.final_configuration.worker_skills) {
            if (!skill.hasOwnProperty('assistant_id') || !skill.assistant_id) {
                if (skill.workspace_id in assistantIds) {
                    skill.assistant_id = assistantIds[skill.workspace_id].assistant_id
                }
            }
        }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step4-1`, {
            method: "POST",
            headers: headers_auth,
            body: JSON.stringify(requestBody, null, 2),
        })
            .then(handleErrors)
            .then((response) => response.json())
            .then((data) => {
                setResponseStep41(JSON.stringify(data, null, 2));
            })
            .catch((error) => console.error("Error fetching data: ", error));
    }

    const handleStepRootSkillCreatorSubstepUploadSkill = () => {
        if (!responseStep41) {
            return;
        }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step4-2`, {
            method: "POST",
            headers: headers_auth,
            body: responseStep41,
        })
            .then(handleErrors)
            .then((response) => response.json())
            .then((data) => {
                setResponseStep42(JSON.stringify(data, null, 2));
            })
            .catch((error) => console.error("Error fetching data: ", error));
    }

    const handleStepRootSkillCreatorSubstepUpdateOrchestrator = () => {
        if (!responseStep42) {
            return;
        }
        fetch(`${ORCHESTRATOR_URL}/api/v1/pipeline/step4-3`, {
            method: "POST",
            headers: headers_auth,
            body: responseStep42,
        })
            .then(handleErrors)
            .then((response) => response.json())
            .then((data) => {
                setResponseStep43(JSON.stringify(data, null, 2));
            })
            .catch((error) => console.error("Error fetching data: ", error));
    }

    return (
        <>
            <Stepper index={activeStep}>
                {steps.map((step, index) => (
                    <Step key={index} onClick={() => handleStepperClick(index)}>
                        <StepIndicator>
                            <StepStatus
                                complete={<StepIcon />}
                                incomplete={<StepNumber />}
                                active={<StepNumber />}
                            />
                        </StepIndicator>

                        <Box flexShrink='0'>
                            <StepTitle>{step.title}</StepTitle>
                            <StepDescription>{step.description}</StepDescription>
                        </Box>

                        <StepSeparator />
                    </Step>
                ))}
            </Stepper>
            <Container maxW='container.md'>
                {(activeStep == 0) &&
                    <>
                        <Divider />
                        <Login
                            onUsernameChange={handleUsername}
                            onPasswordChange={handlePassword}
                            username={username}
                            password={password}
                            watsonAssistantConnectionDetails={watsonAssistantConnectionDetails}
                            loggedIn={loggedIn}
                            onApiKeyChange={handleApiKeyInput}
                            onRegionChange={handleRegionInput}
                            isLoading={isLoading}
                            onContinue={handleStepLogin}
                        />
                    </>
                }
                {(activeStep == 1) &&
                    <>
                        <Divider />
                        <OrchestratorSelection
                            selectedOrch={selectedOrch}
                            itemsArray={itemsArray}
                            stepOrchestratorSelectionResponse={stepOrchestratorSelectionResponse}
                            onOrchestratorSelectionChange={handleOrchestratorSelectionChange}
                            onContinue={handleStepOrchestratorSelection}
                        />
                    </>
                }
                {(activeStep == 2) &&
                    <>
                        <Divider />
                        <OrchestratorModification
                            availableMainSkill={availableMainSkill}
                            availableWorkerSkills={availableWorkerSkills}
                            selectedWorkers={selectedWorkers}
                            availableAdditionalWorkerSkills={availableAdditionalWorkerSkills}
                            selectedLang={selectedLang}
                            stepOrchestratorModificationResponse={stepOrchestratorModificationResponse}
                            onWorkerSkillToggle={handleWorkerSkillToggleChange}
                            onLanguageSelectionChange={handleLanguageSelectionChange}
                            onContinue={handleStepOrchestratorModification}
                        />
                    </>
                }
                {(activeStep == 3) &&
                    <>
                        <Divider />
                        <SetSkillAnalysis
                            availableInputSkills={availableInputSkills}
                            selectedWorkspaces={selectedWorkspaces}
                            onWorkspacesToggle={handleSkillsAnalysisChange}
                            SkillAnalysis={
                                <SkillAnalysis
                                    responseStep31={responseStep31}
                                    onContinue={handleSkillAnalysis}
                                />
                            }
                            IntentsOverlap={
                                <IntentsOverlap
                                    responseStep32={responseStep32}
                                    intent1={intent1}
                                    intent2={intent2}
                                    onIntent1Change={handleIntent1Change}
                                    onIntent2Change={handleIntent2Change}
                                    intentsArray={intentsArray}
                                    onContinue={handleIntentsOverlap}
                                />
                            }
                            IntentsPlot={
                                <IntentsPlot
                                    responseStep33={responseStep33}
                                    selectedWorkspaces={selectedWorkspaces}
                                    workspacePlot={workspacePlot}
                                    onWorkspaceChangePlot={handleWorkspaceChangePlot}
                                    onContinue={handleIntentsPlot}
                                />
                            }
                            TermFrequencyHeatmap={
                                <TermFrequencyHeatmap
                                    responseStep34={responseStep34}
                                    selectedWorkspaces={selectedWorkspaces}
                                    workspaceHeatmap={workspaceHeatmap}
                                    selectedIntents={selectedIntents}
                                    chooseIntents={chooseIntents}
                                    onWorkspaceChangeHeatmap={handleWorkspaceChangeHeatmap}
                                    onSelectionIntentsToggle={handleSelectionIntentsToggle}
                                    onContinueGetIntents={handleIntentsHeatmap}
                                    onContinue={handleTermFrequencyHeatmap}
                                />
                            }
                            IntentsComparison={
                                <IntentsComparison
                                    responseStep35={responseStep35}
                                    isLoading={isLoading}
                                    onContinue={handleIntentsComparison}
                                />
                            }
                            EntitiesComparison={
                                <EntitiesComparison
                                    responseStep36={responseStep36}
                                    isLoading={isLoading}
                                    onContinue={handleEntitiesComparison}
                                />
                            }
                        />
                        <SkillAnalysisFinalization
                            stepSkillAnalysisFinalizationResponse={stepSkillAnalysisFinalizationResponse}
                            responseStep37={responseStep37}
                            onContinue={handleStepSkillAnalysisFinalization}
                            onConfirm={handleConfirmation}
                        />
                    </>
                }
                {(activeStep == 4) &&
                    <>
                        <Divider />
                        <RootSkillCreation
                            stepSkillAnalysisFinalizationResponse={stepSkillAnalysisFinalizationResponse}
                            responseStep41={responseStep41}
                            responseStep42={responseStep42}
                            responseStep43={responseStep43}
                            onGenerateFinalConfig={handleStepRootSkillCreatorSubstepGenerateFinalConfig}
                            onSkillUpload={handleStepRootSkillCreatorSubstepUploadSkill}
                            onOrchestratorUpdate={handleStepRootSkillCreatorSubstepUpdateOrchestrator}
                        />
                    </>
                }
            </Container>
        </>
    );
}

export default function App() {

    return (
        <div>
            <Header />
            <Divider />
            <JsonEditorStep />
        </div>
    )
}
